 .member {
     text-align: center;
     margin-bottom: 80px;
     position: relative;
 }

 .member .pic {
     border-radius: 4px;
     overflow: hidden;
 }

 .member img {
     transition: all ease-in-out 0.4s;
 }

 .member:hover img {
     transform: scale(1.1);
 }

 .member .member-info {
     position: absolute;
     bottom: -80px;
     left: 0px;
     right: 0px;
     background: rgba(255, 255, 255, 0.9);
     padding: 15px 0;
     border-radius: 0 0 4px 4px;
     box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
 }

 .member h4 {
     font-weight: 700;
     margin-bottom: 10px;
     font-size: 16px;
     color: #3c2e96;
     position: relative;
     padding-bottom: 10px;
 }

 .member h4::after {
     content: "";
     position: absolute;
     display: block;
     width: 50px;
     height: 1px;
     background: #3c2e96;
     bottom: 0;
     left: calc(50% - 25px);
 }

 .member span {
     font-style: italic;
     display: block;
     font-size: 13px;
     color: #3c2e96;
 }

 .member .social {
     margin-top: 10px;
 }

 .member .social a {
     transition: color 0.3s;
     color: #3c2e96;
 }

 .member .social a:hover {
     color: #1acc8d;
 }

 .member .social i {
     font-size: 16px;
     margin: 0 2px;
 }