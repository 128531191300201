.cardvr {
    background-color: #d9f4fe;
    border: 2px solid;
    border-color: #00b1f7;
    border-radius: 20px;
    height: 323px;
    /* overflow: hidden; */
    position: relative;
    /* left: 150px; */
    width: 303px;
    cursor: pointer;
    /* transition: all 0.5s; */
    margin: 20px
}

.cardvr.expanded {
    cursor: initial;
    left: 200px;
    width: 700px;
    animation: expand 0.5s ease;
    box-shadow: 0px 1px 49px -5px rgba(0, 177, 247, 0.72);
    -webkit-box-shadow: 0px 1px 49px -5px rgba(0, 177, 247, 0.72);
    -moz-box-shadow: 0px 1px 49px -5px rgba(0, 177, 247, 0.72);
}


@media (max-width: 768px) {
    .cardvr.expanded {
        width: 303px;
        animation: none;
    }

    .cardvr.expanded {
        left: initial;
    }
}

@media (max-width: 1368px) {
    .cardvr.expanded {
        left: initial;
    }
}

@keyframes expand {
    0% {
        width: 303px;
    }

    100% {
        width: 700px;
    }
}

@keyframes shrink {
    0% {
        width: 700px;
    }

    100% {
        width: 303px;
    }
}



@media (min-width: 1368px) {
    .cardvr.shrink {
        animation: shrink 0.5s ease;
    }
}

.cardvr .rectangle {
    background: linear-gradient(180deg,
            rgba(6.38, 6.38, 6.38, 0) 0%,
            rgba(0, 125, 252, 0.62) 50%,
            rgba(6.38, 6.38, 6.38, 0) 100%);
    filter: blur(15px);
    height: 14px;
    left: 40px;
    position: absolute;
    top: 154px;
    width: 209px;
}

.cardvr .text-wrapper {
    color: #175193;
    font-family: "Inter-Bold", Helvetica;
    font-size: 28px;
    font-weight: 700;
    left: 37px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 219px;
    width: 229px;
    /* transition: all 0.5s; */
    user-select: none;
}

.cardvr:hover .text-wrapper {
    top: 200px;
    left: 5px;
    transition: all 0.5s;
}

.expanded .text-wrapper {
    top: 70px;
    left: 5px;
}

.expanded:hover .text-wrapper {
    top: 70px;
    left: 5px;
}

.vr-headset {
    width: 250px;
    position: absolute;
    top: 30px;
    left: 0;
    overflow: visible;
    /* transition: all 0.5s; */
}

.cardvr:hover .vr-headset {
    width: 320px;
    transform: rotate(15deg);
    top: -50px;
    transition: all 0.5s;
}

.expanded:hover .vr-headset {
    width: 150px;
    transform: rotate(15deg);
    top: -50px;
    right: 10px;
    left: auto;
    transition: all 0.5s;
}

@media (max-width: 768px) {
    .cardvr.shrink:hover .vr-headset {
        width: 250px;
        transform: rotate(0deg);
        top: 30px;
        left: 10;
        transition: all 0.5s;
    }
}


.expanded .vr-headset {
    width: 150px;
    transform: rotate(15deg);
    top: -50px;
    right: 10px;
    left: auto;
    transition: all 0.5s;
    overflow: visible;
}


.vr-desc {
    position: absolute;
    bottom: 0;
    visibility: hidden;
    text-align: center;
    top: 300px;
    left: 30px;
    width: 250px;
    text-align: left;
    overflow: hidden;
    transition: all 0.5s;
    user-select: none;
}

@media (max-width: 768px) {
    .d-vr {
        width: 250px;
        height: 250px;
        word-wrap: wrap;
    }
}

.cardvr:hover .vr-desc {
    visibility: visible;
    top: 240px;
    transition: all 0.5s;
}

.expanded.vr-desc {
    visibility: hidden;
    transition: all 0s;
}

.expanded:hover .vr-desc {
    visibility: hidden;
    transition: all 0s;
}


.cardvr:hover {
    overflow: visible;
    box-shadow: 0px 1px 49px -5px rgba(0, 177, 247, 0.72);
    -webkit-box-shadow: 0px 1px 49px -5px rgba(0, 177, 247, 0.72);
    -moz-box-shadow: 0px 1px 49px -5px rgba(0, 177, 247, 0.72);
    transition: box-shadow 0.5s;
}

.d-vr {
    position: absolute;
    bottom: 0;
    text-align: center;
    top: 300px;
    left: 30px;
    /* width: 250px; */
    text-align: left;
    overflow: hidden;
    user-select: none;
    visibility: hidden;
    user-select: none;
    transition: all 0s;
}

.expanded .d-vr {
    visibility: visible;
    user-select: none;
    top: 120px;
    left: 30px;
    transition: all 0.5s;
}

.expanded:hover .d-vr {
    visibility: visible;
    user-select: none;
    top: 120px;
    left: 30px;
    transition: all 0.5s;
}

.cardvr .back-btn {
    visibility: hidden;
}

.expanded .back-btn {
    visibility: visible;
    width: 40px;
    position: absolute;
    top: 8px;
    left: 8px;
    cursor: pointer;
}

.cardvr .connect-btn {
    visibility: hidden;
}

.expanded .connect-btn {
    visibility: visible;
    position: absolute;
    bottom: 30px;
    right: 50px;
}

.cardvr.codeReady .connect-btn {
    transition: none;
    visibility: hidden;
}

.cardvr.codeReady .d-vr {
    transition: none;
    visibility: hidden;
}

.cardvr.codeReady .text-wrapper {
    transition: none;
    visibility: hidden;
}

.cardvr.codeReady:hover .d-vr {
    transition: none;
    visibility: hidden;
}

.cardvr.codeReady:hover .text-wrapper {
    transition: none;
    visibility: hidden;
}

.connected {
    visibility: hidden;
}

.cardvr.codeReady .connected {
    visibility: visible;
    color: #175193;
    font-family: "Inter-Bold", Helvetica;
    font-size: 28px;
    font-weight: 700;
    left: 220px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 60px;
    width: 229px;
    user-select: none;
}

.cardvr.codeReady .vr-headset {
    right: 290px;
}

@media (max-width: 768px) {
    .cardvr.codeReady .vr-headset {
        right: 80px;
    }

    .cardvr.codeReady .connected {
        left: 20px;
        top: 20px
    }
}

.line {
    visibility: hidden;
}

.cardvr.codeReady .line {
    visibility: visible;
    position: absolute;
    top: 95px;
    left: 190px;
}

@media (max-width: 768px) {
    .cardvr.codeReady .line {
        visibility: visible;
        position: absolute;
        top: 60px;
        left: 0;
    }
}

.ins {
    visibility: hidden;
}

.cardvr.codeReady .ins {
    visibility: visible;
    position: absolute;
    top: 120px;
    left: 150px;
    color: #979797;
    font-family: "Inter-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    width: 400px;
    user-select: none;
}

@media (max-width: 768px) {
    .cardvr.codeReady .ins {
        top: 90px;
        left: -50px;
    }
}


.vrBox .vrRectangle {
    visibility: hidden;
}

.cardvr.codeReady .vrBox {
    visibility: visible;
    height: 64px;
    width: 304px;
}

.cardvr.codeReady .vrBox .vrRectangle {
    visibility: visible;
    background-color: #b2eaff;
    border: 2px solid;
    border-color: #00b1f7;
    border-radius: 5px;
    height: 64px;
    width: 304px;
    position: absolute;
    top: 150px;
    left: 190px;
}

@media (max-width: 768px) {
    .cardvr.codeReady .vrBox .vrRectangle {
        top: 150px;
        left: 25px;
        width: 80%;
    }

    .cardvr.codeReady .vrBox .code {
        left: 10px;
    }
}

.code {
    visibility: hidden;
}

.cardvr.codeReady .code {
    visibility: visible;
    color: #175193;
    font-family: "Inter-Bold", Helvetica;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    width: 229px;
    user-select: none;
    top: 15px;
    left: 35px;
}

.conn {
    position: absolute;
    top: 30px
}