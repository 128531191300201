.cardb {
    background-color: #d9f4fe;
    border: 2px solid;
    border-color: #00b1f7;
    border-radius: 20px;
    height: 323px;
    /* overflow: hidden; */
    position: relative;
    width: 303px;
    cursor: pointer;
    /* transition: all 0.5s; */
    margin: 20px
}



.cardb.expanded {
    right: 200px;
    cursor: initial;
    width: 700px;
    animation: expand 0.5s ease;
    box-shadow: 0px 1px 49px -5px rgba(0, 177, 247, 0.72);
    -webkit-box-shadow: 0px 1px 49px -5px rgba(0, 177, 247, 0.72);
    -moz-box-shadow: 0px 1px 49px -5px rgba(0, 177, 247, 0.72);
}


@media (max-width: 768px) {
    .cardb.expanded {
        width: 303px;
        animation: none;
        right: initial;
    }
}

@media (max-width: 1368px) {
    .cardb.expanded {
        right: initial;
    }
}

@keyframes expand {
    0% {
        width: 303px;
    }

    100% {
        width: 700px;
    }
}

@media (min-width: 1368px) {
    .cardb.shrink {
        animation: shrink 0.5s ease;
    }
}


@keyframes shrink {
    0% {
        width: 700px;
    }

    100% {
        width: 303px;
    }
}

.cardb.shrink .desktop {
    animation: reset 0.5s ease;
}

@keyframes reset {
    0% {
        top: -40px;
    }

    100% {
        top: 50px;
        right: 50px;
    }
}

.cardb .rectangle {
    background: linear-gradient(180deg,
            rgba(6.38, 6.38, 6.38, 0) 0%,
            rgba(0, 125, 252, 0.62) 50%,
            rgba(6.38, 6.38, 6.38, 0) 100%);
    filter: blur(15px);
    height: 14px;
    left: 40px;
    position: absolute;
    top: 154px;
    width: 209px;
}

.cardb .text-wrapper-b {
    color: #175193;
    font-family: "Inter-Bold", Helvetica;
    font-size: 28px;
    font-weight: 700;
    left: 37px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 219px;
    width: 229px;
    /* transition: all 0.5s; */
    user-select: none;
}

.cardb:hover .text-wrapper-b {
    top: 200px;
    left: -50px;
    transition: all 0.5s;
}

.expanded .text-wrapper-b {
    top: 70px;
    left: -50px;
}

.expanded:hover .text-wrapper-b {
    top: 70px;
    left: -50px;
}


.cardb .button {
    background-color: #d9f4fe;
    height: 86px;
    left: 0;
    position: absolute;
    top: 313px;
    width: 285px;
}

.cardb .div {
    color: #979797;
    font-family: "Inter-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 29px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 10px;
    width: 239px;
}

.desktop {
    width: 200px;
    position: absolute;
    top: 50px;
    left: 50px;
    overflow: visible;
    /* transition: all 0.5s; */
}

.cardb:hover .desktop {
    width: 250px;
    transform: rotate(15deg);
    top: -30px;
    transition: all 0.5s;
}



.expanded:hover .desktop {
    width: 150px;
    top: -40px;
    left: auto;
    right: 0px;
    transform: none;
    transition: all 0.5s;
}

@media (max-width: 768px) {
    .cardb.shrink:hover .desktop {
        width: 200px;
        transform: rotate(0deg);
        top: 50px;
        left: 50px;
        transition: all 0.5s;
    }
}

.expanded .desktop {
    width: 150px;
    top: -40px;
    right: 0;
    left: auto;
    transform: none;
    transition: all 0.5s;
    overflow: visible;
}

.vr-desc {
    position: absolute;
    bottom: 0;
    visibility: hidden;
    text-align: center;
    top: 300px;
    left: 30px;
    width: 250px;
    text-align: left;
    overflow: hidden;
    transition: all 0.5s;
    user-select: none;
}

.cardb:hover .vr-desc {
    visibility: visible;
    top: 240px;
    transition: all 0.5s;
}

.expanded.vr-desc {
    visibility: hidden;
    transition: all 0s;
}

.expanded:hover .vr-desc {
    visibility: hidden;
    transition: all 0s;
}


.cardb:hover {
    overflow: visible;
    box-shadow: 0px 1px 49px -5px rgba(0, 177, 247, 0.72);
    -webkit-box-shadow: 0px 1px 49px -5px rgba(0, 177, 247, 0.72);
    -moz-box-shadow: 0px 1px 49px -5px rgba(0, 177, 247, 0.72);
    transition: box-shadow 0.5s;
}

.d-vr {
    position: absolute;
    bottom: 0;
    text-align: center;
    top: 300px;
    left: 30px;
    /* width: 250px; */
    text-align: left;
    overflow: hidden;
    user-select: none;
    visibility: hidden;
    user-select: none;
    transition: all 0s;
}

.expanded .d-vr {
    visibility: visible;
    user-select: none;
    top: 120px;
    left: 30px;
    transition: all 0.5s;
}

.expanded:hover .d-vr {
    visibility: visible;
    user-select: none;
    top: 120px;
    left: 30px;
    transition: all 0.5s;
}

.cardb .back-btn {
    visibility: hidden;
}

.expanded .back-btn {
    visibility: visible;
    width: 40px;
    position: absolute;
    top: 8px;
    left: 8px;
    cursor: pointer;
}

.cardb .connect-btn {
    visibility: hidden;
}

.expanded .connect-btn {
    visibility: visible;
    position: absolute;
    bottom: 30px;
    right: 50px;
}