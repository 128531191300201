.team {
    background: #fff;
}

.team {
    text-align: center;
    margin-bottom: 80px;
    position: relative;
}

.team .pic {
    border-radius: 4px;
    overflow: hidden;
}

.team img {
    transition: all ease-in-out 0.4s;
}

.team:hover img {
    transform: scale(1.1);
}

.team-info {
    position: absolute;
    bottom: -80px;
    left: 0px;
    right: 0px;
    background: rgba(255, 255, 255, 0.9);
    padding: 15px 0;
    border-radius: 0 0 4px 4px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.team h4 {
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 16px;
    color: #01036f;
    position: relative;
    padding-bottom: 10px;
}

.team h4::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 1px;
    background: #0d12fc;
    bottom: 0;
    left: calc(50% - 25px);
}

.team span {
    font-style: italic;
    display: block;
    font-size: 13px;
    color: #01036f;
}

.team .social {
    margin-top: 10px;
}

.team .social a {
    transition: color 0.3s;
    color: #01036f;
}

.team .social a:hover {
    color: #1acc8d;
}

.team .social i {
    font-size: 16px;
    margin: 0 2px;
}