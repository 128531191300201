body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: #1acc8d;
  text-decoration: none;
}

a:hover {
  color: #34e5a6;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

.description p {
  text-align: justify;
}

.gif {
  display: flex;
  justify-content: center;
  margin: auto;
}

video::-webkit-media-controls-volume-slider {
  display: none;
}

video::-webkit-media-controls-mute-button {
  display: none;
}

.feature {
  display: flex;
  justify-content: center;
  padding: 2%;
}

@media (max-width: 992px) {
  .feature {
    flex-direction: column-reverse;
  }
}

.feature .gif {
  padding: 2%;
}

.feature .description {
  padding: 2%;
  width: 50%;
  font-size: 18px;
  line-height: 1.8;
  font-family: "Poppins", sans-serif;
  margin-top: 90px;
}

@media (max-width: 992px) {
  .feature .description {
    width: 100%;
  }
}

.avatars {
  position: relative;
}

.avatar-img {
  display: inline-block;
  width: 100%;
  height: 100%;
  min-width: 600px;
}

.ff {
  color: #3c2e96;
}

.title {
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 25px;
  font-size: 18px;
}

.btn-features {
  margin-top: 30px;
  background-color: #3c2e96 !important;
  width: 300px;
}


#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #1acc8d;
  border-top-color: #d2f9eb;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #1acc8d;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #2be4a2;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}


#header {
  height: 80px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  background: rgba(1, 4, 136, 0.9);
}

#header.header-transparent {
  background: transparent;
}

#header.header-scrolled {
  background: #3c2e96;
  height: 60px;
}

#header .logo h1 {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #fff;
  text-decoration: none;
}

#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}

.navbar {
  padding: 0;
}

.navbar-item {
  color: white !important;
}

@media screen and (max-width: 975px) {
  .navbar-item {
    color: black !important;
  }
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 15px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar>ul>li>a:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 3px;
  left: 30px;
  background-color: #1acc8d;
  visibility: hidden;
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
}

.navbar a:hover:before,
.navbar li:hover>a:before,
.navbar .active:before {
  visibility: visible;
  width: 25px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #fff;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 30px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #01036f;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #1acc8d;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(1, 3, 91, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 8px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile>ul>li>a:before {
  left: 20px;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #0205a1;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #3f43fd;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul,
.navbar-mobile .dropdown .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li,
.navbar-mobile .dropdown .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a,
.navbar-mobile .dropdown .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i,
.navbar-mobile .dropdown .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a,
.navbar-mobile .dropdown .dropdown ul a:hover,
.navbar-mobile .dropdown .dropdown ul .active:hover,
.navbar-mobile .dropdown .dropdown ul li:hover>a {
  color: #1acc8d;
}

.navbar-mobile .dropdown>.dropdown-active,
.navbar-mobile .dropdown .dropdown>.dropdown-active {
  display: block;
}

#hero {
  width: 100%;
  position: relative;
  padding: 120px 0 0 0;
}

#hero:before {
  content: "";
  background: #4939af;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero h1 {
  margin: 0 0 20px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: rgba(255, 255, 255, 0.8);
}

#hero h1 span {
  color: #fff;
  border-bottom: 4px solid #1acc8d;
}

#hero h2 {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 40px;
  font-size: 24px;
}

#hero .btn-get-started {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background: #1acc8d;
  border: 0px;
}

#hero .btn-get-started:hover {
  background: #17b57d;
}

#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 991px) {
  #hero {
    padding-top: 80px;
  }

  #hero .animated {
    -webkit-animation: none;
    animation: none;
  }

  #hero .hero-img {
    text-align: center;
  }

  #hero .hero-img img {
    max-width: 50%;
  }

  #hero h1 {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 10px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

.hero-waves {
  display: block;
  margin-top: 60px;
  width: 100%;
  height: 60px;
  z-index: 5;
  position: relative;
}

.wave1 use {
  -webkit-animation: move-forever1 10s linear infinite;
  animation: move-forever1 10s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.wave2 use {
  -webkit-animation: move-forever2 8s linear infinite;
  animation: move-forever2 8s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.wave3 use {
  -webkit-animation: move-forever3 6s linear infinite;
  animation: move-forever3 6s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

@-webkit-keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }

  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }

  100% {
    transform: translate(-90px, 0%);
  }
}

@-webkit-keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}

@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}

@-webkit-keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}

@keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}

.about {
  padding: 40px 0 0 0;
}

.about .gif {
  display: flex;
  justify-content: center;
  width: 41%;
}

@media (max-width: 992px) {
  .about .gif {
    width: 100%;
  }
}

.about .gif img {
  height: 55%;
  margin: auto;
}

.about .icon-boxes h3 {
  font-size: 28px;
  font-weight: 700;
  color: #3c2e96;
  margin-bottom: 15px;
}

.about .icon-box {
  margin-top: 40px;
  height: 50px;
}

.about .icon-box .icon {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border: 2px solid #7ceec6;
  border-radius: 50px;
  transition: 0.5s;
}

.about .icon-box .icon i {
  color: #1acc8d;
  font-size: 32px;
}

.about .icon-box:hover .icon {
  background: #1acc8d;
  border-color: #1acc8d;
}

.about .icon-box:hover .icon i {
  color: #fff;
}

.about .icon-box .title {
  margin-left: 85px;
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 18px;
}

.about .icon-box .title a {
  color: #343a40;
  transition: 0.3s;
}

.about .icon-box .title a:hover {
  color: #1acc8d;
}

.about .icon-box .description {
  margin-left: 85px;
  line-height: 24px;
  font-size: 14px;
}

.about .video-box {
  background-size: contain;
  min-height: 400px;
}

.about .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(#3f43fd 50%, rgba(63, 67, 253, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(80% - 47px);
  overflow: hidden;
}

.about .play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.about .play-btn::before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate-btn 2s;
  animation: pulsate-btn 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(63, 67, 253, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.about .play-btn:hover::after {
  border-left: 15px solid #3f43fd;
  transform: scale(20);
}

.about .play-btn:hover::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  -webkit-animation: none;
  animation: none;
  border-radius: 0;
}

section {
  padding: 60px 0;
  overflow: hidden;
}

.section-text {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  margin-top: 20px;
  line-height: 1.3;
  text-align: justify;
  text-justify: inter-word;
  padding-left: 10px;
  padding-right: 10px;
}

.breadcrumbs {
  padding: 20px 0;
  background-color: #fafaff;
  min-height: 40px;
  margin-top: 80px;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 64px;
  }
}

.breadcrumbs h2 {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}

@media (max-width: 992px) {
  .breadcrumbs h2 {
    margin: 0 0 10px 0;
  }
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6c757d;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}


@-webkit-keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@media (max-width: 992px) {
  .gi {
    border-radius: 40px;
  }

  .col-6 {
    width: 100%;
  }
}

.submit {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background: #1acc8d;
  margin-top: 10px;
}



@media (max-width: 992px) {
  .col-2 {
    width: 100%;
  }
}

.team {
  background: #fff;
}

.team .member {
  text-align: center;
  margin-bottom: 80px;
  position: relative;
}

.team .member .pic {
  border-radius: 4px;
  overflow: hidden;
}

.team .member img {
  transition: all ease-in-out 0.4s;
}

.team .member:hover img {
  transform: scale(1.1);
}

.team .member .member-info {
  position: absolute;
  bottom: -80px;
  left: 0px;
  right: 0px;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px 0;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.team .member h4 {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 16px;
  color: #01036f;
  position: relative;
  padding-bottom: 10px;
}

.team .member h4::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: #0d12fc;
  bottom: 0;
  left: calc(50% - 25px);
}

.team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
  color: #01036f;
}

.team .member .social {
  margin-top: 10px;
}

.team .member .social a {
  transition: color 0.3s;
  color: #01036f;
}

.team .member .social a:hover {
  color: #1acc8d;
}

.team .member .social i {
  font-size: 16px;
  margin: 0 2px;
}


.pricing .box {
  padding: 20px;
  background: #fff;
  text-align: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.pricing h3 {
  font-weight: 400;
  margin: -20px -20px 20px -20px;
  padding: 20px 15px;
  font-size: 16px;
  font-weight: 600;
  color: #777777;
  background: #f8f8f8;
}

.pricing h4 {
  font-size: 36px;
  color: #1acc8d;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.pricing h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: #444444;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.pricing ul li {
  padding-bottom: 16px;
}

.pricing ul i {
  color: #1acc8d;
  font-size: 18px;
  padding-right: 4px;
}

.pricing ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing .btn-wrap {
  margin: 20px -20px -20px -20px;
  padding: 20px 15px;
  background: #f8f8f8;
  text-align: center;
}

.pricing .btn-buy {
  background: #1acc8d;
  display: inline-block;
  padding: 8px 35px 10px 35px;
  border-radius: 50px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  transition: 0.3s;
}

.pricing .btn-buy:hover {
  background: #149f6e;
}

.pricing .featured h3 {
  color: #fff;
  background: #1acc8d;
}

.pricing .advanced {
  width: 200px;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
  background: #1acc8d;
  color: #fff;
}

.map-container {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  border-radius: 40px;
}

.map-container iframe {
  left: 0;
  top: 0;
  margin-top: 20px;
  height: 100%;
  width: 100%;
  position: absolute;
}

#tour {
  margin-top: 50px;
}

#blog {
  margin-top: 50px;
}

.tour_description {
  font-size: 20px;
  line-height: 1.8;
  font-family: "Poppins", sans-serif;
  margin-bottom: 40px;
}

.blog-container {
  top: 50%;
  left: 50%;
}


.continue-button {
  position: absolute;
  left: 25%;
  bottom: 2%;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background: #1acc8d;
}

.flex-child {
  padding-left: 5%;
  padding-right: 5%;
}

.flex-child:first-child {
  margin-right: 20px;
}


.about {
  text-align: center;
  line-height: 32px;
  font-family: "Montserrat", sans-serif;
}

.about h1 {
  font-size: 48px;
}

.about h3 {
  color: #1acc8d;
  font-size: 20px;
}

.about h5 {
  color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 5%;
  margin-right: 5%;
}

/* styles reset */

.btn-primary:hover,
.btn-primary:focus {
  background-color: #108d6f;
  border-color: #108d6f;
  box-shadow: none;
  outline: none;
}

.btn-primary {
  color: #fff;
  border-color: #007b5e;
}

section {
  padding: 60px 0;
}

section .section-title {
  color: #007b5e;
  text-transform: uppercase;
}

/* value cards */



.image-flip:hover .backside,
.image-flip.hover .backside {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  border-radius: 40px;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.mainflip {
  -webkit-transition: 1s;
  -webkit-transform-style: preserve-3d;
  -ms-transition: 1s;
  -moz-transition: 1s;
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transition: 1s;
  transform-style: preserve-3d;
  position: relative;
}

.frontside {
  position: relative;
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  z-index: 2;
  margin-bottom: 30px;
}

.backside {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
  -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
  box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.frontside,
.backside {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 1s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 1s;
  -moz-transform-style: preserve-3d;
  -o-transition: 1s;
  -o-transform-style: preserve-3d;
  -ms-transition: 1s;
  -ms-transform-style: preserve-3d;
  transition: 1s;
  transform-style: preserve-3d;
}


/* /value cards */

.tour p {
  display: block;
  line-height: 1.666;
  font-family: "Poppins", sans-serif;
  text-align: justify;
  text-justify: inter-word;
}

.tour i {
  padding-right: 2px;
  color: #61ebba;
  font-size: 18px;
  line-height: 1;
}

.tour h4 {
  margin-bottom: 20px;
  text-transform: uppercase;
}

/*--------------------------------------------------------------
# features page
--------------------------------------------------------------*/
.logo-book-a-tour {
  height: 110px !important;
  margin-bottom: 20px;
}

#title-tour {
  font-weight: 700;
  color: #3c2e96;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0;
  margin-right: 0;
}

@media (min-width: 476px) {
  #title-tour::after {
    content: "";
    width: 80px;
    height: 1px;
    display: inline-block;
    background: #1acc8d;
    margin: 4px 10px;
  }

  #title-tour::before {
    content: "";
    width: 80px;
    height: 1px;
    display: inline-block;
    background: #1acc8d;
    margin: 4px 10px;
  }
}



.yt {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 80%;
  height: 80%;
  border-radius: 25px;
  margin-left: auto;
  margin-right: auto;
}

.work p {
  font-family: "Poppins", sans-serif;
  font-size: 21px;
  padding-bottom: 25px;
}

.fullscreen {
  cursor: pointer;
  color: white;
  background-color: rgba(1, 4, 136, 0.9);
  font-family: "Montserrat", sans-serif;
}

#unity-canvas {
  box-shadow: #010246;
}

.phone-input-container input[type="text"] {
  border: none !important;
}


.section-bg {
  background-color: #f5f5ff;
}


.section-title {
  margin-top: 50px;
}

.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: "Poppins", sans-serif;
}

.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #1acc8d;
  margin: 4px 10px;
}

.section-title p {
  margin-bottom: 50px;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #3c2e96;
}

.contact .info {
  background: #fff;
}

.contact .info i {
  font-size: 20px;
  color: #3f43fd;
  float: left;
  width: 44px;
  height: 44px;
  background: #f0f0ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #3c2e96;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #3c2e96;
}

.contact .info .email,
.contact .info .phone {
  margin-bottom: 30px;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: #1acc8d;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}


.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
  background: #1acc8d;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #34e5a6;
}

.box-sh {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 20px;
  padding: 20px;
  display: inline-block;
  width: auto;
}

.md {
  text-align: center;
}

.yo {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background-color: #1acc8d;
  border: 0;
  line-height: 40px;
  box-sizing: border-box;
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  position: absolute;
  bottom: '50%';
  left: '50%';
  transform: 'translate(-50%, 50%)'
}

.video-card-text {
  font-size: 15px;
  overflow: hidden;
  text-align: justify;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical
}