.error-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    padding: 20px;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0px 2px 4px rgb(2, 87, 164, 0.3);
    text-align: center;
    z-index: 101;
    border-radius: 10px;
}

.error-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.error-header h2 {
    font-family: "Poppins", Helvetica;
    font-size: 25px;
}

.close-button {
    background-color: #0257a4;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s, border 0.3s, color 0.3s;
    border-radius: 10px;
}

.close-button:hover {
    background-color: white;
    border: 1px solid #0257a4;
    color: #0257a4;
}

.error-message {
    font-size: 16px;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    /* Semi-transparent black background */
    z-index: 100;
    /* Ensure the backdrop is on top of other content */
}