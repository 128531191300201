.bg-video video {
    height: 100%;
    width: 150%;
    position: relative;
    left: 0;
}

.cont {
    position: relative;
    height: 80vh;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
}

.base {
    position: absolute;
    margin-top: 10%;
    margin-left: 50px;
    width: 50%;
    height: 50%;
}

.video {
    object-fit: contain !important;
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

#herosection {
    padding: 0;
    overflow: hidden;
}

@media (max-width: 1250px) {
    .cont {
        position: relative;
        height: auto;

    }

    .overlay {
        position: relative;
        width: 70%;

    }

    .base {
        width: 100%;
        position: relative;
        top: 0;
        margin: 20px;
    }

}



#herosection {
    width: 100%;
    position: relative;
}

#herosection:before {
    content: "";
    background: #3C2E96;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

#herosection h1 {
    margin: 0 0 20px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: rgba(255, 255, 255, 0.8);
}

#herosection h1 span {
    color: #fff;
    border-bottom: 4px solid #1acc8d;
}

#herosection h2 {
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 40px;
    font-size: 24px;
}

#herosection .btn-get-started {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 50px;
    transition: 0.5s;
    color: #fff;
    background: #1acc8d;
    border: 0px;
}

#herosection .btn-get-started:hover {
    background: #17b57d;
}

#herosection .animated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (min-width: 1024px) {
    #herosection {
        background-attachment: fixed;
    }
}

@media (max-width: 991px) {
    #herosection {
        padding-top: 80px;
    }

    #herosection .animated {
        -webkit-animation: none;
        animation: none;
    }

    #herosection .hero-img {
        text-align: center;
    }

    #herosection .hero-img img {
        max-width: 50%;
    }

    #herosection h1 {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 10px;
    }

    #herosection h2 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
    }
}

@media (max-width: 575px) {
    #herosection .hero-img img {
        width: 80%;
    }
}

@-webkit-keyframes up-down {
    0% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-10px);
    }
}

@keyframes up-down {
    0% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-10px);
    }
}

.hero-waves {
    display: block;
    margin-top: 60px;
    width: 100%;
    height: 60px;
    z-index: 5;
    position: relative;
}

.wave1 use {
    -webkit-animation: move-forever1 10s linear infinite;
    animation: move-forever1 10s linear infinite;
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
}

.wave2 use {
    -webkit-animation: move-forever2 8s linear infinite;
    animation: move-forever2 8s linear infinite;
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
}

.wave3 use {
    -webkit-animation: move-forever3 6s linear infinite;
    animation: move-forever3 6s linear infinite;
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
}

@-webkit-keyframes move-forever1 {
    0% {
        transform: translate(85px, 0%);
    }

    100% {
        transform: translate(-90px, 0%);
    }
}

@keyframes move-forever1 {
    0% {
        transform: translate(85px, 0%);
    }

    100% {
        transform: translate(-90px, 0%);
    }
}

@-webkit-keyframes move-forever2 {
    0% {
        transform: translate(-90px, 0%);
    }

    100% {
        transform: translate(85px, 0%);
    }
}

@keyframes move-forever2 {
    0% {
        transform: translate(-90px, 0%);
    }

    100% {
        transform: translate(85px, 0%);
    }
}

@-webkit-keyframes move-forever3 {
    0% {
        transform: translate(-90px, 0%);
    }

    100% {
        transform: translate(85px, 0%);
    }
}

@keyframes move-forever3 {
    0% {
        transform: translate(-90px, 0%);
    }

    100% {
        transform: translate(85px, 0%);
    }
}