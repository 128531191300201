.upcoming {
    padding: 80px 0;
    background: url("../../assets/img/talan-bg.webp") no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
}

.upcoming::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(60, 46, 150, 0.8);
}

.upcoming .section-header {
    margin-bottom: 40px;
}

.upcoming .upcoming-carousel,
.upcoming .upcoming-slider {
    overflow: hidden;
}

.upcoming .testimonial-item {
    text-align: center;
    color: #fff;
}

.upcoming .testimonial-item .testimonial-img {
    width: 100px;
    border-radius: 50%;
    border: 6px solid rgba(255, 255, 255, 0.15);
    margin: 0 auto;
}

.upcoming .testimonial-item h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #fff;
}

.upcoming .testimonial-item h4 {
    font-size: 14px;
    color: #ddd;
    margin: 0 0 15px 0;
}

.upcoming .testimonial-item .quote-icon-left,
.upcoming .testimonial-item .quote-icon-right {
    color: rgba(255, 255, 255, 0.4);
    font-size: 26px;
}

.upcoming .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
}

.upcoming .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
}

.upcoming .testimonial-item p {
    font-style: italic;
    margin: 0 auto 15px auto;
    color: #eee;
}

.upcoming .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.upcoming .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.4);
}

.upcoming .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #1acc8d;
}

@media (min-width: 1024px) {
    .upcoming {
        background-attachment: fixed;
    }
}

@media (min-width: 992px) {
    .upcoming .testimonial-item p {
        width: 80%;
    }
}

.gallery .gallery-item {
    overflow: hidden;
    border-right: 3px solid #fff;
    border-bottom: 3px solid #fff;
}

.gallery .gallery-item img {
    transition: all ease-in-out 0.4s;
    height: 200px;
}

.gallery .gallery-item:hover img {
    transform: scale(1.1);
}

#upcoming .swiper-pagination .swiper-pagination-bullet {
    background-color: #ffff;
}

#upcoming .swiper-button-next {
    color: rgba(255, 255, 255, 0.5);
}

#upcoming .swiper-button-prev {
    color: rgba(255, 255, 255, 0.5);
}