.my-unity-app {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.full-scrn {
    position: fixed;
    bottom: 10px;
    right: 10px;
    margin: 5px;
    z-index: 9999;
    background-color: black;
    opacity: 0.5;
    border-radius: 50%;
    color: #ffffff;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
}

.disconnect {
    position: fixed;
    top: 20px;
    right: 20px;
    margin: 5px;
    z-index: 9999;
    background-color: black;
    opacity: 0.5;
    border-radius: 50%;
    color: #ffffff;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
}