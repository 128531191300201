.form-input {
    border-radius: 20px;
    resize: none;
}

.form-input:focus {
    border-color: #1acc8d;
}

.contact .php-email-form .form-group {
    padding-bottom: 8px;
}

input.submit {
    border-width: 0px;
}