.disconnect {
    position: fixed;
    top: 20px;
    right: 20px;
    margin: 5px;
    z-index: 9999;
    background-color: black;
    opacity: 0.5;
    border-radius: 50%;
    color: #ffffff;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
}

.update-avatar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    /* Semi-transparent black background */
    z-index: 98;
}

.closeButton {
    position: absolute;
    z-index: 999;
    right: 0;
    top: 0;
    cursor: pointer;
    color: white;

}

.rpm {
    width: 90%;
    height: 95vh;
    z-index: 99;
    position: fixed;
    border-radius: 20px;
    top: 0;
    margin-top: 20px;
    margin-left: 5%;
}