.cont-l {
    height: 100vh;
    background: #2980B9;
    background: -webkit-linear-gradient(to bottom, #BEEEF5, #FFFFFF, rgba(41, 128, 185, 0.2));
    background: linear-gradient(to bottom, #BEEEF5, #FFFFFF, rgba(41, 128, 185, 0.4));
}



.frame-wrapper-l {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 600px;
}


.frame-wrapper-l img {
    position: relative;
    width: 300px;
    height: auto;
}



.label .text-wrapper-l {
    color: black;
    font-weight: 400;
    font-family: "Poppins", Helvetica
}