.frontside .card-value,
.backside .card-value {
    min-height: 430px;
    max-width: 280px;
    box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.card-value-title {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 50px;
    color: #fff;
    background: #3c2e96;
    margin-top: 10px;
    box-shadow: 5px 4px 5px -4px rgb(158, 158, 158);
}

.card-value-text {
    display: block;
    line-height: 1.666;
    font-family: "Poppins", sans-serif;
    margin: 10% 10% 10% 10%;
}

.frontside .card-value .card-value-body img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin-top: 20%;
}