.article-header {
    margin-top: 100px;
}

.article-header h1 {
    color: #3c2e96;
    font-family: "Poppins", sans-serif;
    margin: auto;
    width: 50%;
}

.article-header img {
    max-height: 400px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.article img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 50%;
}

@media screen and (max-width: 975px) {
    #main-article {
        margin-top: 50px;
        display: flex;
        flex-direction: column-reverse;
    }

    .article {
        margin-left: 5%;
        margin-right: 5%;
    }

    .article-header img {
        display: none;
    }

    .article-header h1 {
        margin: 5%;
        width: 100%;
    }
}

#main-article {
    margin-top: 50px;
    display: flex;
}

.author {
    margin-top: 5%;
    margin-left: 2%;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}

.author img {
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
}

.bio {
    font-style: italic;
    font-weight: 300;
}

.article {
    font-family: "Poppins", sans-serif;
    margin-right: 25%;
    align-items: center;
}

.article h1 {
    color: #3c2e96;
    margin-bottom: 30px;
}

.article h3 {
    color: #1acc8d;
    margin-bottom: 20px;
}

.article p {
    display: block;
    line-height: 1.666;
    font-size: large;
    text-align: justify;
}

.article h4 {
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 40px;
    font-weight: 700;
}