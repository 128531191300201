.timeline-with-icons {
    border-left: 1px solid hsl(0, 0%, 90%);
    position: relative;
    list-style: none;
}

.timeline-with-icons .timeline-item {
    position: relative;
}

.timeline-with-icons .timeline-item:after {
    position: absolute;
    display: block;
    top: 0;
}

.timeline-with-icons .timeline-icon {
    position: absolute;
    left: -48px;
    background-color: #ffff;
    color: hsl(217, 88.8%, 35.1%);
    border-radius: 50%;
    height: 31px;
    width: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #1acc8d;
}

.crd {
    border: 0px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.crd h5 {
    font-weight: 600;
    margin-bottom: 15px;
}

/* The actual timeline (the vertical ruler) */
.main-timeline-2 {
    position: relative;
}


/* The actual timeline (the vertical ruler) */
.main-timeline-2::after {
    content: "";
    position: absolute;
    width: 2px;
    background-color: #1acc8d;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
    z-index: -1;
}

/* Container around content */
.timeline-2 {
    position: relative;
    background-color: inherit;
    width: 50%;
}

/* The circles on the timeline */

.timeline-2 .fa {
    font-family: "FontAwesome";
    position: absolute;
    width: 40px;
    height: 40px;
    right: -18px;
    top: 15px;
    font-size: 25px;
    color: #1acc8d;
    background-color: white;
    border-radius: 50%;
    border: 2px solid #1acc8d;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* Place the container to the left */
.left-2 {
    padding: 0px 40px 20px 0px;
    left: 0;
}

/* Place the container to the right */
.right-2 {
    padding: 0px 0px 20px 40px;
    left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left-2::before {
    content: " ";
    position: absolute;
    top: 18px;
    z-index: 1;
    right: 30px;
    border: medium solid white;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.right-2::before {
    content: " ";
    position: absolute;
    top: 18px;
    z-index: 1;
    left: 30px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */
.right-2 .fa {
    left: -21px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {

    /* Place the timelime to the left */
    .main-timeline-2::after {
        left: 31px;
    }

    /* Full-width containers */
    .timeline-2 {
        width: 100%;
        padding-left: 70px;
        padding-right: 25px;
    }

    /* Make sure that all arrows are pointing leftwards */
    .timeline-2::before {
        left: 60px;
        border: medium solid white;
        border-width: 10px 10px 10px 0;
        border-color: transparent white transparent transparent;
    }

    /* Make sure all circles are at the same spot */
    .left-2 .fa,
    .right-2 .fa {
        left: 10px;
    }

    .left-2::before {
        right: auto;
    }

    /* Make all right containers behave like the left ones */
    .right-2 {
        left: 0%;
    }
}