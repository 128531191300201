.HERO-PAGE {
    height: 100vh;
    background: -webkit-linear-gradient(to bottom, #BEEEF5, #FFFFFF, rgba(41, 128, 185, 0.2));
    background: linear-gradient(to bottom, #BEEEF5, #FFFFFF, rgba(41, 128, 185, 0.4));
    overflow-x: hidden;
}



.frame-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;

}



.the-ultimate {
    color: #77838f;
    font-family: "Poppins", Helvetica;
    font-size: 25px;
    font-weight: 400;
    height: 130px;
    letter-spacing: 0;
    /* white-space: nowrap; */
}


.group {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}


.meta-white {
    width: 60%;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 20px;
    opacity: 0;
    animation: fadeInOut 5s linear infinite;
}

.cards {
    display: flex;
    justify-content: space-between;
    margin-left: 20%;
    margin-right: 20%;
}



.moto {
    color: #77838f;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 32px;
    font-weight: 400;
    text-wrap: nowrap;
    margin-top: 20px;
    user-select: none;
}


@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.microsoft-button {
    opacity: 0;
}

.ellipse-2 {
    background: radial-gradient(50% 50% at 50% 50%, rgb(39, 163, 213) 0%, rgb(2, 87, 164) 100%);
    border-radius: 19.5px;
    filter: blur(1px);
    height: 30px;
    left: 10%;
    top: 50px;
    position: absolute;
    width: 30px;
}


.ellipse-3 {
    border: 7px solid;
    border-color: #0257a4;
    border-radius: 27px;
    filter: blur(1px);
    height: 54px;
    left: 30%;
    position: absolute;
    top: 60%;
    width: 54px;
}

.ellipse-4 {
    background: radial-gradient(50% 50% at 50% 50%, rgb(39, 163, 213) 0%, rgb(2, 87, 164) 100%);
    border-radius: 50%;
    height: 50px;
    left: 80%;
    position: absolute;
    top: 20%;
    width: 50px;
    filter: blur(1px);
}

.ellipse-5 {
    background: radial-gradient(50% 50% at 50% 50%, rgb(39, 163, 213) 0%, rgb(2, 87, 164) 100%);
    border-radius: 50%;
    filter: blur(1px);
    height: 70px;
    right: 10%;
    position: absolute;
    top: 85%;
    width: 70px;
}

.vector-2 {
    position: absolute;
    top: 80%;
    left: 5%;
}

.vector {
    position: absolute;
    top: 10%;
    left: 40%;
}

.vector-7 {
    position: absolute;
    top: 50%;
    right: 95%;
    width: 40px;
    height: 40px;
}

.polygon {
    position: absolute;
    top: 20%;
    left: 65%;
}

.floating-elements {
    animation: float 2s ease-in-out infinite;
    /* height: 100vh; */
}

.float-v {
    animation: floatVertical 2s ease-in-out infinite;
}

.float-h {
    animation: floatHorizontal 2s ease-in-out infinite;
}

.metatalan-logo {
    margin-top: 120px;
    z-index: 99;
}

@keyframes floatVertical {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translate(-5px, -5px)
    }
}

@keyframes floatHorizontal {

    0%,
    100% {
        transform: translateX(0);
    }

    50% {
        transform: translate(-5px, 5px);
    }
}

@media (max-width: 768px) {

    .metatalan-logo {
        width: 200px;
    }

    .moto {
        font-size: 24px;
        margin-top: 10px;
        word-wrap: pretty
    }

    .the-ultimate {
        font-size: 20px;
    }

    .group {
        margin-top: 10px;
    }

    .ellipse-2 {
        top: 10px;
        left: 10px;
    }
}

@media (max-width: 1368px) {

    .cards {
        flex-direction: column;
    }

    .moto {
        text-wrap: pretty
    }
}