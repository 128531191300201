.loading {
    transform: translate(-50%, -50%);
    margin: 0;
    width: 250px;
    height: 250px;
    filter: drop-shadow(1px 2px 14px #000);
    animation: spin 4s linear infinite;
}

/* keyframe animation for the rotation */
@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


.owniverse-bg {
    width: 100vw;
    height: 100vh;
}

.cn {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
}

.cn img {
    width: 250px;
    height: 250px;
    filter: drop-shadow(1px 2px 14px #000);
    animation: spin 4s linear infinite;
    opacity: 1;
}

.loading-text {
    color: antiquewhite;
    font-family: Georgia, serif;
    font-size: 24px;
    color: #FFFFFF;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    text-shadow: 4px 1px 5px rgba(0, 0, 0, 0.49);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    margin-top: 170px;
}