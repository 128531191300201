.gif {
    display: flex;
    justify-content: left;
    margin: auto;
}

.gif:hover {
    cursor: pointer;
}

@media (max-width: 768px) {

    .solution-description {
        margin-bottom: 0;
        height: 150px;
    }
}

@media (max-width: 576px) {


    .solutions-feature {
        margin-bottom: 30px !important;
    }

    .solution-description {
        margin-bottom: 0;
        height: 30%;
    }
}