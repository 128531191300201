.c-box {
    height: 54px;
    width: 230px;
    cursor: pointer;
}

.c-box .c-rectangle {
    background-color: #0257a4;
    border: 2px solid;
    border-color: #00b1f7;
    border-radius: 8px;
    box-shadow: 0px 0px 12px 2px #15459966;
    height: 54px;
    left: 0;
    position: absolute;
    top: 0;
    width: 230px;
}

.connect {
    color: #d9f4fe;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 24px;
    font-weight: 500;
    height: 36px;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 12px;
    width: 229px;
}