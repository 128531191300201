.loading-bar {
    align-items: flex-start;
    border: 2px solid;
    border-color: #22d1ee;
    border-radius: 76px;
    display: flex;
    flex-direction: column;
    gap: 47px;
    justify-content: center;
    padding: 2px;
    position: relative;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.loading-bar .button {
    all: unset;
    align-items: center;
    background: linear-gradient(180deg, rgb(34, 209, 238) 0%, rgb(61, 90, 241) 100%);
    border-radius: 47px;
    box-sizing: border-box;
    display: flex;
    gap: 8px;
    height: 20px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 19px;
    transition: padding 0.3s ease-in-out;
}


.loading-bar .text-wrapper {
    color: transparent;
    font-family: "Poppins-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: -17px;
    margin-left: -36px;
    margin-right: -40px;
    margin-top: -19px;
    position: relative;
    text-align: center;
    width: fit-content;
}

.percentage {
    color: #64717e;
    font-family: "Poppins-Bold", Helvetica;
    font-size: 18px;
    font-weight: 700;
    height: 29px;
    left: 0;
    letter-spacing: 0;
    line-height: 49.5px;
    text-align: center;
    top: 0;
    white-space: nowrap;
}