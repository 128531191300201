.card-title {
    color: #3c2e96
}


.category {
    font-size: 12px;
    color: #ffff;
    background-color: #6f42c1;
    border-radius: 10px;
    white-space: nowrap;
    display: inline-block;
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
}

.c-col {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: 'flex';
    justify-content: 'center';
    align-items: 'flex-end';
}

.b-card {
    border: 0;
    margin: 10px;
}